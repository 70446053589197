import React from 'react'

const CompABan = (props) => (
    <section id="banner" className="style2 bg-image-compa">
        <div className="inner">
            <header className="major">
                <h1>Competency A</h1>
            </header>
            <div className="content">
                <p>Apply technology informatics skills to solve specific industry data and information management problems, with a focus on usability and designing for users</p>
            </div>
        </div>
    </section>
)

export default CompABan
