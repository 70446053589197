import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import CompABan from "../components/CompABan";

import pic09 from "../assets/images/compA/analytics-ipad-ui-large2.jpg";
import pic10 from "../assets/images/compA/sketch-large.jpg";
import pic11 from "../assets/images/compA/app-iphone-ui.jpg";
import pic12 from "../assets/images/compA/cactus.jpg";
import pic13 from "../assets/images/compA/metadata-agave.jpg";
import pic14 from "../assets/images/compA/dam-prototype.jpg";
import pic15 from "../assets/images/compA/hierarchy-tree.jpg";
import pic16 from "../assets/images/compA/tagging.jpg";

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Competency A</title>
      <meta name="description" content="Competency A" />
    </Helmet>

    <CompABan />

    <div id="main">
      <section id="intro">
        <div className="inner">
          <header className="major">
            <h2>Introduction</h2>
          </header>
          <p>
            Informatics is a broad sub-discipline of information science that
            focuses on the connection between humans and data systems to create
            usable and actionable systems for users (SJSU School of Information,
            2020). Informatics skills can be applied to a wide range of
            different industries to solve usability problems and improve user
            experience (UX). Usability refers to a technology’s ease of use from
            a user’s point of view and is essential to help people navigate
            complex data systems (Nielsen, 2012). As a professional user
            interface (UI) designer, I have been able to create designs that
            provide users with experiences that match their needs. This program
            has also provided me with the opportunity to approach usability
            design from a more technical, data-driven point of view, which has
            helped improve my UI designs and better understand users.
          </p>
        </div>
      </section>
      <section id="overview">
        <div className="inner">
          <header className="major-black">
            <h2>Coursework Overview</h2>
          </header>
          <p>
            Throughout this program and in my professional experiences, I have
            had the opportunity to solve industry data and information
            management problems by focusing on usability design. My work
            experience has provided me with real-world information management
            problems that can only be solved by empathizing with a user’s
            technological needs. This program has also helped me further
            understand and improve my UX skills by introducing me to taxonomy
            and metadata design. I explored these concepts in INFM 207, Digital
            Assets Management, and created a taxonomy system for a botanical
            garden. This experience was vital for me to understand how to
            organize and simplify complex data through metadata systems.
            Additionally, an important part of this program has been discussing
            various informatics issues with my classmates. In INFM 200,
            Informatics Fundamentals, I had the chance to discuss the
            difficulties users encounter through poor taxonomy classifications
            with my fellow classmates. These discussions, and the responses to
            my own posts, helped me understand data management problems across
            many different industries and careers. It was inspiring to discuss
            taxonomy complexities with so many of my classmates and discover the
            wide breadth of the informatics discipline.
          </p>

          <div className="grid-wrapper">
            <div className="col-4">
              <div className="box">
                <h3>Evidence 1</h3>
                <p>
                  Work experience: Data analytics dashboard for a video
                  surveillance mobile application.
                </p>
                <a href="#two" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <h3>Evidence 2</h3>
                <p>
                  INFM 207 Project: Taxonomic and metadata model design for a
                  botanical garden's digital assets managment system
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <h3>Evidence 3</h3>
                <p>
                  INFM 200 Discussion: Taxonomic classification's role in
                  information managment and my professional experiences.
                </p>
                <a href="#four" className="button">
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <img src={pic09} alt="Image of data dashboards on iPads" className="image fit" />
              </div>
              <div className="col-6">
                <header className="major">
                  <h3>
                    Evidence 1: Work Experience Data Visualization Dashboard
                  </h3>
                </header>
                <p>
                  I have had the opportunity to work professionally as a UI
                  designer and solve complicated data management problems by
                  simplifying complex information. This piece of evidence is an
                  example of an interface that I created for a client in the
                  video security and surveillance field. Restaurants,
                  convenience stores, and various types of businesses used this
                  mobile application to monitor their stores remotely through
                  Wi-Fi-enabled cameras. These cameras were powered by robust
                  analytical software and had the ability to count people,
                  identify customer traffic patterns, and customer dwelling
                  times. Although the cameras had powerful analytics abilities,
                  the data created by this software was very difficult to
                  understand, especially from the perspective of a typical
                  restaurant or business owner. In order to solve this complex
                  information management problem, I designed a dashboard that
                  simplified the data and allowed users to view their store’s
                  analytics visually through graphs, heatmaps, and at-a-glance
                  data summaries. By focusing on usability and designing from a
                  typical user’s point of view, business owners were easily able
                  to view their shop’s traffic patterns, peak sales times, and
                  trends over time.
                </p>
              </div>

              <div className="col-6">
                <div className="box">
                  <img src={pic10} alt="Sketches of user interfaces" className="image fit" />
                  <p>
                    <i>Preliminary sketches for the app</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic11} alt="iPhone screens with analytics data" className="image fit" />
                  <p>
                    <i>An example of the analytics app on the iPhone</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <header className="major">
                  <h3>Evidence 2: Taxonomy and Metadata Project</h3>
                </header>
                <p>
                  Taxonomy and classification schemes are essential to solve
                  information management problems and enhance a system’s
                  usability. For this project, I focused on building a taxonomy
                  and metadata model for a digital assets management (DAM)
                  system used by a botanical garden. The DAM system I created
                  provided an organized digital library for photographs,
                  graphics, and videos of the collection’s plant specimens. The
                  system’s metadata model was inspired by fields from the Dublin
                  Core Metadata Element Set, Darwin Core’s plant classification
                  standards, and various video metadata elements found on
                  operating systems like Windows and MacOS.
                </p>
                <p>  The taxonomy scheme
                  for the DAM used a simplified taxonomic genus-species
                  structure with a hierarchy of three levels deep. This shallow
                  hierarchy allowed plants to be grouped into four main
                  categories, followed by scientific plant families, and finally
                  the plant’s genus level. In order to improve usability, I
                  created an interface for the DAM that featured a dedicated
                  predictive search bar that was accessible from all levels of
                  the system. Users could search for all metadata types included
                  in the DAM and receive results based on the scientific or
                  common names of the plant species. All assets were displayed
                  with a thumbnail of the specimen with the title of the
                  specimen under the asset. By focusing on building a strong
                  taxonomy scheme and metadata model, I was able to improve
                  usability and create a simple DAM system to digitally catalog
                  the botanical garden’s plant species.
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1yggqXseRSz-qMIRaj7ojEm-Py2Qf0CKn/view?usp=sharing" target="_blank" className="button next">
                    Read the Project
                  </a>
                </p>
              </div>
              <div className="col-6">
                <img src={pic12} alt="A cactus garden with a quote from the project" className="image fit" />
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic13} alt="Example of a metadata model" className="image fit" />
                  <p>
                    <i>An example of a plant's metadata model</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic14} alt="Low fidelity wireframes for the DAM system" className="image fit" />
                  <p>
                    <i>A low-fidelity wireframe of the DAM system</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="four">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-12">
                <header className="major">
                  <h3>
                    Evidence 3: Classification and Taxonomy Discussion Post
                  </h3>
                </header>
                <p>
                  Discussions with my fellow classmates have helped me better
                  understand information management problems in different
                  industries and learn how to solve these diverse data problems.
                  In INFM 200, we discussed the role that taxonomy and
                  classification schemes play in improving usability and data
                  accessibility. My post focused on my professional experience
                  designing a hierarchical taxonomy tree for a surveillance
                  camera management application. This application initially had
                  a flat structure, and it was difficult for users with many
                  cameras to navigate the system. For example, a restaurant
                  owner might have 100 franchise or chain locations, and the
                  application’s flat hierarchical structure made it difficult
                  for the user to find specific cameras at certain locations. In
                  order to solve this problem, I created a taxonomy tree that
                  organized cameras by location, district, and finally
                  individual stores. After applying this taxonomy scheme to the
                  camera management application, users could easily find the
                  cameras they were looking for. After sharing my experiences, I
                  learned about how my classmates’ used taxonomies in their
                  workplaces or everyday lives. Some people used taxonomies to
                  successfully redesign websites for banks or schools, while
                  others used taxonomies to organize files on their computers.
                  After this discussion, I realized that there were many
                  applications for improving information access and management
                  through taxonomy schemes for both business and personal use.
                </p>
                <p>
                  <a
                    href="https://drive.google.com/file/d/1wAtyT3YJHmGJOXEVa5TEexyLKy9m2FpC/view?usp=sharing" target="_blank" className="button next ">
                    Read the Discussion Post
                  </a>
                </p>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic15} alt="Example of a hierarchy tree covering geographic locations of stores" className="image fit" />
                  <p>
                    <i>An example of a hierarchy tree used for this application</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic16} alt="Example of a UI highlighting folksonomy tagging" className="image fit" />
                  <p>
                    <i>An example of folksonomy tagging for administrator users</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="conclusion">
        <div className="inner">
          <header className="major">
            <h2>Conclusion</h2>
          </header>
          <p>
            Designing for users and focusing on usability is an extremely
            important informatics skill and can be used to improve information
            management problems in a wide variety of industries. I have learned
            a lot about UX design through my professional experiences designing
            analytics dashboards and interfaces for end users, and it’s
            important to focus on the functions of a system from a user’s point
            of view. INFM 207 introduced me to creating advanced taxonomy
            schemes and metadata models, which are essential to create easily
            accessible, well-organized data systems. Finally, INFM 200 allowed
            me to share my experiences creating hierarchies with my classmates,
            and I also had the opportunity to learn about their experiences with
            classification schemes. This competency has helped me learn about
            usability from many different perspectives and improve my UX design
            skills by using taxonomies and metadata models.
          </p>
          <div className="box">
            <h3>References</h3>
            <p>
              Nielsen, J. (2012, January 3). Usability 101: Introduction to
              Usability. Retrieved from Nielsen Norman Group:
              https://www.nngroup.com/articles/usability-101-introduction-to-usability/
            </p>
            <p>
              SJSU School of Information. (2020). Master of Science in
              Informatics. Retrieved from SJSU School of Information:
              https://ischool.sjsu.edu/ms-informatics
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Landing;
